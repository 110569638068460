<template>
  <article>
    <div class="page-container">
      <main style="display: contents">
        <section class="height-full mtb-auto">
          <div class="content-band flex align-center justify-center max-w700">
            <div
              class="
                container
                basis-50
                max-w500
                flex
                align-center
                justify-center
              "
            >
              <img
                src="../assets/401-image.png"
                style="width: 230px"
                alt="401 Image"
              />
            </div>
            <div
              class="
                container
                flex
                justify-center
                align-center
                basis-50
                error-message
              "
            >
              <div class="container max-w300">
                <h1 class="font-iop-blue error-number light">401</h1>
                <div class="error-pagenotfound">Unauthorized Error</div>
                <p class="max-w300">
                  You may need to log in or create an OH|ID account to see this
                  page.
                </p>
                <button
                  class="
                    primary
                    iop-drk-blue
                    mt-30
                    rounded-full
                    hover-white hover-shadow hover-font-blue
                  "
                >
                  <div class="btn-txt font-white">Sign in to OH|ID</div>
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div
        class="container flex justify-center align-center gray-200"
        style="height: 120px"
      >
        <div>
          <img src="../assets/iop-logo-gray.png" alt="Innovate Ohio Platform Logo" />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "UnauthorizedErrorComponent",
  props: ["type", "subtype", "text", "icon"],
};
</script>

<style scoped>
.error-number {
  font-size: 86px;
}
.error-pagenotfound {
  color: #557aed;
  font-size: 30px;
}
@media only screen and (max-width: 600px) {
  .error-message {
    text-align: -webkit-center;
  }
}
</style>
