<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Error 401</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              The 401 Unauthorized Error is the HTTP status code that indicates
              that the request has not been fulfilled because the user lacks
              valid credentials, however, authentication is possible if the user
              logs in.
            </p>
            <div class="gray-250 p-30 w800 mtb-30">
              <div class="font-red semibold">Suggested messaging:</div>
              <p>
                401 / You need to log into your OH|ID account to see this page.
              </p>
              <p>{{ buttonTextExample }}</p>
            </div>
          </div>
        </section>

        <div class="divider w1200"></div>

        <section>
          <div class="content-band">
            <h3>Design Vew</h3>
            <CodeView block="pages" type="401-error-page">
              <UnauthorizedErrorComponent
                type="primary"
                text="UnathorizedError"
              />
            </CodeView>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import TempLeftNav from "../TempLeftNav";
import UnauthorizedErrorComponent from "../UnauthorizedErrorComponent";
import CodeView from "../CodeView";
export default {
  name: "UnathorizedError",
  data: function() {
    return {
      buttonTextExample: "Button: <Sign to OH|ID>",
      primary: {
        main: {
          name: "Error 401",
          codeHTML: `<!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link rel="preconnect" href="https://fonts.gstatic.com">
        <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap" rel="stylesheet">
        <link rel="stylesheet" href="../../assets/css/ds-core.css">
        <title>401 Error Page</title>
    </head>
    
    <body>
        <div class="page-container">
            <main style="display: contents;">
                <section class="height-full mtb-auto">
                    <div class="content-band flex align-center justify-center max-w700">
                        <div class="container basis-50 max-w500 flex align-center justify-center">
                            <img src= "../assets/401-image.png" style="width: 230px; height: 280px;" alt="401 Image">
                        </div>
                        <div class="container flex justify-center align-center basis-50 error-message">
                            <div class="container max-w300">
                                <h1 class="font-iop-blue error-number light">401</h1>
                                <div class="error-pagenotfound">Unauthorized Error</div>
                                <p class="max-w300">
                                    You may need to log in or create an OH|ID account to see this page.
                                </p>
                                <button class="primary iop-drk-blue mt-30 rounded-full hover-white hover-shadow hover-font-blue">
                                    <div class="btn-txt font-white">Sign in to OH|ID</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <div class="container flex justify-center align-center gray-200" style="height: 120px;">
                <div>
                    <img src="./assets/iop-logo-gray.png" alt="Innovate Ohio Platform Logo"/>
                </div>
            </div>
        </div>
    </body>
</html>`,
          codeCSS: `           
			.error-number {
                font-size: 86px;
            }
            .error-pagenotfound {
                color: #557aed;
                font-size: 30px;
            }
            @media only screen and (max-width: 600px) {
                .error-message {
                    text-align: -webkit-center;
                }
            }`,
          codeJS: "No JS Needed",
          preview: "This is the preview",
        },
        mainWithIcon: {},
        disabled: {},
      },
    };
  },
  components: {
    TempLeftNav,
    UnauthorizedErrorComponent,
    CodeView,
  },
};
</script>
